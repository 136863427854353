require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/MapTest/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var points, item, tables;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tables = (await Backendless.Data.of('Location').find(Backendless.DataQueryBuilder.create().setWhereClause((['distanceOnSphere(GeoLoc,',(new Backendless.Data.Point().setX((getObjectProperty((await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })()), 'lng'))).setY((getObjectProperty((await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })()), 'lat')))),')<=100000'].join(''))).setPageSize(100)));
  points = (await Promise.all(tables.map(async item => {; return (Object.assign((getObjectProperty(item, 'GeoLoc')), ({ 'Table': (getObjectProperty(item, 'TableLoc')),'City': (getObjectProperty(item, 'City')) })));})));
  (function (componentUid, points) { ___arguments.context.getComponentByUid(componentUid).points = points })('7d1d94badf111794399903d35d58e599', points);

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, lat, lng) { ___arguments.context.getComponentByUid(componentUid).center = { lat, lng } })('7d1d94badf111794399903d35d58e599', (getObjectProperty((await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })()), 'lat')), (getObjectProperty((await ( async function () { return BackendlessUI.Navigator.getCurrentGeolocation() })()), 'lng')));
  (function (componentUid, zoom) { ___arguments.context.getComponentByUid(componentUid).zoom = zoom })('7d1d94badf111794399903d35d58e599', 14);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/MapTest/components/7d1d94badf111794399903d35d58e599/bundle.js', [], () => ({
  /* content */
  /* handler:onMarkerClick */
  ['onMarkerClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, marker, popupContent) { ___arguments.context.getComponentByUid(componentUid).map.showInfoWindow(marker, { content: popupContent }) })('7d1d94badf111794399903d35d58e599', ___arguments.point, (getObjectProperty(___arguments.point, 'Table')));

  },
  /* handler:onMarkerClick */
  /* content */
}))

define('./pages/about/components/cb5d4e74ae8a79457ee4d6af2eb3b9df/bundle.js', [], () => ({
  /* content */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop >= 0 && ___arguments.scrollTop <= 50) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a443b3d5c113133f20dcab672065a2ba'))['b'] = '0px 0px';
  }
  if (___arguments.scrollTop >= 51 && ___arguments.scrollTop <= 100) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a443b3d5c113133f20dcab672065a2ba'))['b'] = '0px -50px';
  }
  if (___arguments.scrollTop >= 101 && ___arguments.scrollTop >= 150) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a443b3d5c113133f20dcab672065a2ba'))['b'] = '0px -75px';
  }
  if (___arguments.scrollTop >= 151 && ___arguments.scrollTop >= 200) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a443b3d5c113133f20dcab672065a2ba'))['b'] = 'ss';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/about/components/82e1ad551244560549aead69dfc4786c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://apps.apple.com/tt/app/streettt/id1630838316', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/d4c82846aa9a138737c66a06374678d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://play.google.com/store/apps/details?id=com.streettt.admin.signin2', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/bb2adbc567d8b4bb91eebb5a99be742c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('mailto:admin@streettt.com', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/f09e88aaa1e329749a0763626969bfb7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://t.me/streettabletennis', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/b39a74b5f0ce79e2ed61b9e4eace1cec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.youtube.com/@StreetTTapp', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/3ed2fe19389c39deb29715c268471f48/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.instagram.com/streetttapp/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/9102904e082ed40164acfd610eb562cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.facebook.com/streetttapp/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/7ca955bcab4409d7bd14d224cef3b784/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://forms.gle/h7LyZQnoBjJesDmA8', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/competition/components/852f150d4797181e524d6fe6cb494054/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')), 'show');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/competition/components/cb5d4e74ae8a79457ee4d6af2eb3b9df/bundle.js', [], () => ({
  /* content */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop > 100 && ___arguments.scrollTop < 150) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip260.png)';
  }
  if (___arguments.scrollTop > 150 && ___arguments.scrollTop < 200) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip261.png)';
  }
  if (___arguments.scrollTop > 200 && ___arguments.scrollTop < 250) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip262.png)';
  }
  if (___arguments.scrollTop > 250 && ___arguments.scrollTop < 300) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip263.png)';
  }
  if (___arguments.scrollBottom > 0 && ___arguments.scrollBottom < 50) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9fa1b9acfefce364d9f37ef866b9cbde'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip062.png)';
  }
  if (___arguments.scrollBottom > 50 && ___arguments.scrollBottom < 100) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9fa1b9acfefce364d9f37ef866b9cbde'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip061.png)';
  }
  if (___arguments.scrollBottom > 100 && ___arguments.scrollBottom < 150) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9fa1b9acfefce364d9f37ef866b9cbde'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip060.png)';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/competition/components/82e1ad551244560549aead69dfc4786c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://apps.apple.com/tt/app/streettt/id1630838316', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/competition/components/d4c82846aa9a138737c66a06374678d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://play.google.com/store/apps/details?id=com.streettt.admin.signin2', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/competition/components/0cd4e361337afcc30644468e054e91d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2f4baa24eb160f2bab034c5e942682c5')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/852f150d4797181e524d6fe6cb494054/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')), 'show');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/82e1ad551244560549aead69dfc4786c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://apps.apple.com/tt/app/streettt/id1630838316', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/d4c82846aa9a138737c66a06374678d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://play.google.com/store/apps/details?id=com.streettt.admin.signin2', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/7529b502b2be9bbfafdc6b9e317597e6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7529b502b2be9bbfafdc6b9e317597e6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2bb1a17dac0f33ff7d577785fcf6e461', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d0ea0eecbe968a2c5a6467c3d847649', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('caf52d58b74f71ad4c0e5acf40c73ce5')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/2bb1a17dac0f33ff7d577785fcf6e461/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7529b502b2be9bbfafdc6b9e317597e6', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2bb1a17dac0f33ff7d577785fcf6e461', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d0ea0eecbe968a2c5a6467c3d847649', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('caf52d58b74f71ad4c0e5acf40c73ce5')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/37777ba97b17b5a39496cedda435720b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('37777ba97b17b5a39496cedda435720b', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('09996c0ce033e87fe5dbf82524323f1e', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a79487361b28095c2bef79fd57120aa', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f8c2c6e65afc4bfc8b2aa2e73b578dad')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/09996c0ce033e87fe5dbf82524323f1e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('09996c0ce033e87fe5dbf82524323f1e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('37777ba97b17b5a39496cedda435720b', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a79487361b28095c2bef79fd57120aa', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f8c2c6e65afc4bfc8b2aa2e73b578dad')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/9bbcede684a836744ab142e9c07566e3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9bbcede684a836744ab142e9c07566e3', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4bcf4f86bb67009f3fe129c6ed33794a', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('265e74a4e911f602d78b062121af20e6', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f6c85aa11332435b46d850776b37b2f6')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/4bcf4f86bb67009f3fe129c6ed33794a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4bcf4f86bb67009f3fe129c6ed33794a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9bbcede684a836744ab142e9c07566e3', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('265e74a4e911f602d78b062121af20e6', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f6c85aa11332435b46d850776b37b2f6')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/97c9d58c7c6a83eebb0dddff01db3ad6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('97c9d58c7c6a83eebb0dddff01db3ad6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1aa58b24b2508344f551b3e40518a64', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('999b9f83259bbf695f75d29522da2a16', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7b588bc1c87a9e14510f84dc60152524')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/d1aa58b24b2508344f551b3e40518a64/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1aa58b24b2508344f551b3e40518a64', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('97c9d58c7c6a83eebb0dddff01db3ad6', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('999b9f83259bbf695f75d29522da2a16', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7b588bc1c87a9e14510f84dc60152524')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/f12d620954ce4f86226358a6c9430161/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f12d620954ce4f86226358a6c9430161', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc9b5fad048a8074f182231b1f188ff3', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d5a572b95319fa6df79b75e9cf9d2759', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('072fa3c968d282f91dc9d31a4dec0736')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/dc9b5fad048a8074f182231b1f188ff3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc9b5fad048a8074f182231b1f188ff3', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f12d620954ce4f86226358a6c9430161', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d5a572b95319fa6df79b75e9cf9d2759', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('072fa3c968d282f91dc9d31a4dec0736')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/24fa89b38d7fd2740ca66bf55ad18115/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('24fa89b38d7fd2740ca66bf55ad18115', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90fb57ed7dff9e52013f2553e3764aa1', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8838cb0cd557e9dbc1de8a4eaa1158f8', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b537967a7ce81a15044e3d5fef844711')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/90fb57ed7dff9e52013f2553e3764aa1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('24fa89b38d7fd2740ca66bf55ad18115', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('90fb57ed7dff9e52013f2553e3764aa1', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8838cb0cd557e9dbc1de8a4eaa1158f8', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b537967a7ce81a15044e3d5fef844711')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/a6c5ea843bfcb292d00f805b7c51aabe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6c5ea843bfcb292d00f805b7c51aabe', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1e51b2f40c150d51cfb99947308b91e8', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('875bc895cc7a656cc8869ee89961c9f2', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e90835a662afb04abf4ba81a25a9b4ab')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/1e51b2f40c150d51cfb99947308b91e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1e51b2f40c150d51cfb99947308b91e8', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('875bc895cc7a656cc8869ee89961c9f2', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a6c5ea843bfcb292d00f805b7c51aabe', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e90835a662afb04abf4ba81a25a9b4ab')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/6de20df7f655cbfc795b0d1a267d4a2f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6de20df7f655cbfc795b0d1a267d4a2f', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('02bd47bdb0cdd4f37fc23d865d38fe62', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('98d1a99e14c25a958881056b78f5d759', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6c87b933c0ad9ee9be17e5e563015e83')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/02bd47bdb0cdd4f37fc23d865d38fe62/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('02bd47bdb0cdd4f37fc23d865d38fe62', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('98d1a99e14c25a958881056b78f5d759', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6de20df7f655cbfc795b0d1a267d4a2f', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6c87b933c0ad9ee9be17e5e563015e83')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/e701bb3f9f99585dd74e9117add9336b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e701bb3f9f99585dd74e9117add9336b', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('01fa5f09cfb7c947e28f02cef889160b', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cb35b217eb435835d05531669b8af982', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('14c22d08c4282c110c1a6c5d0e7c0537')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/01fa5f09cfb7c947e28f02cef889160b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('01fa5f09cfb7c947e28f02cef889160b', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cb35b217eb435835d05531669b8af982', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e701bb3f9f99585dd74e9117add9336b', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('14c22d08c4282c110c1a6c5d0e7c0537')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/27aecb428fea11e2bfb7762690a23c1d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27aecb428fea11e2bfb7762690a23c1d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ff1450e05cd7232eb07b95f0b1e4623', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2552aa7edb0b6f218e8502ce3b9f990d', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('8574532eb40a834d96b6fa9507b37098')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/3ff1450e05cd7232eb07b95f0b1e4623/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ff1450e05cd7232eb07b95f0b1e4623', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2552aa7edb0b6f218e8502ce3b9f990d', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27aecb428fea11e2bfb7762690a23c1d', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('8574532eb40a834d96b6fa9507b37098')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/6b51d383eb61dd9bcd42f74fdbb27381/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b51d383eb61dd9bcd42f74fdbb27381', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('baaeaa1de07265bfed0770c169ada935', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c04659e97c54d0aeadb845efe10b404f', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('5284cd94ce3b709d425b0284cfa1d67b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/baaeaa1de07265bfed0770c169ada935/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('baaeaa1de07265bfed0770c169ada935', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c04659e97c54d0aeadb845efe10b404f', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b51d383eb61dd9bcd42f74fdbb27381', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('5284cd94ce3b709d425b0284cfa1d67b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/a958c21c58c60b3f10499768622aef94/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a958c21c58c60b3f10499768622aef94', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('34d50a62934eb751508a41631b79ac0e', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6ada20b8b7d053ec96b3c7d3e3cf10f', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('51925cd8b854507ca8f11ffef5a3999b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/guide/components/34d50a62934eb751508a41631b79ac0e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('34d50a62934eb751508a41631b79ac0e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6ada20b8b7d053ec96b3c7d3e3cf10f', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a958c21c58c60b3f10499768622aef94', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('51925cd8b854507ca8f11ffef5a3999b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var letSeconds, letMinutes, letTimeLeft, letHours, letDays, letMainDate, letConfigurationObj, markers, j, maps;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  markers = [];
  maps = (await Backendless.Data.of('Location').find(Backendless.DataQueryBuilder.create().setPageSize(100)));
  for (var j_index in maps) {
    j = maps[j_index];
    addItemToList(markers, ({ [`lat`]: (getObjectProperty((getObjectProperty(j, 'GeoLoc')), 'y')),[`lng`]: (getObjectProperty((getObjectProperty(j, 'GeoLoc')), 'x')),[`markerData`]: (getObjectProperty(j, 'TableLoc')) }));
  }
  ___arguments.context.pageData['mapMarker'] = markers;
  letConfigurationObj = (await Backendless.Data.of('Configuration').findFirst());
  letMainDate = (getObjectProperty(letConfigurationObj, 'mainDate'));
  while (letMainDate - ((new Date()).valueOf()) > 0) {
    letTimeLeft = letMainDate - ((new Date()).valueOf());
    letSeconds = 1000;
    letMinutes = letSeconds * 60;
    letHours = letMinutes * 60;
    letDays = letHours * 24;
    ___arguments.context.pageData['CountdownDays'] = (Math.floor(letTimeLeft / letDays));
    ___arguments.context.pageData['CountdownHours'] = (Math.floor((letTimeLeft % letDays) / letHours));
    ___arguments.context.pageData['CountdownMinutes'] = (Math.floor((letTimeLeft % letHours) / letMinutes));
    ___arguments.context.pageData['CountdownSeconds'] = (Math.floor((letTimeLeft % letMinutes) / letSeconds));
    await new Promise(r => setTimeout(r, 1000 || 0));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/home/components/82e1ad551244560549aead69dfc4786c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://apps.apple.com/tt/app/streettt/id1630838316', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/d4c82846aa9a138737c66a06374678d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://play.google.com/store/apps/details?id=com.streettt.admin.signin2', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/bb2adbc567d8b4bb91eebb5a99be742c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('mailto:admin@streettt.com', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/f09e88aaa1e329749a0763626969bfb7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://t.me/streettabletennis', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/b39a74b5f0ce79e2ed61b9e4eace1cec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.youtube.com/@StreetTTapp', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/3ed2fe19389c39deb29715c268471f48/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.instagram.com/streetttapp/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/9102904e082ed40164acfd610eb562cb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://www.facebook.com/streetttapp/', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/b9a87f06c1d873336e8d7d72da9464aa/bundle.js', [], () => ({
  /* content */
  /* handler:onPointsAssignment */
  ['onPointsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'mapMarker')) == null ? [] : (getObjectProperty(___arguments.context.pageData, 'mapMarker')))

  },
  /* handler:onPointsAssignment */
  /* handler:onMarkerClick */
  ['onMarkerClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, marker, popupContent) { ___arguments.context.getComponentByUid(componentUid).map.showInfoWindow(marker, { content: popupContent }) })('b9a87f06c1d873336e8d7d72da9464aa', ___arguments.point, (getObjectProperty(___arguments.point, 'markerData')));

  },
  /* handler:onMarkerClick */
  /* handler:onMapCenterAssignment */
  ['onMapCenterAssignment'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'mapMarker')) == null ? ({  }) : (getObjectProperty(___arguments.context.pageData, 'mapMarker'))[0])

  },
  /* handler:onMapCenterAssignment */
  /* content */
}))

define('./pages/legal/components/852f150d4797181e524d6fe6cb494054/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')), 'show');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/legal/components/cb5d4e74ae8a79457ee4d6af2eb3b9df/bundle.js', [], () => ({
  /* content */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop > 100 && ___arguments.scrollTop < 150) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip260.png)';
  }
  if (___arguments.scrollTop > 150 && ___arguments.scrollTop < 200) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip261.png)';
  }
  if (___arguments.scrollTop > 200 && ___arguments.scrollTop < 250) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip262.png)';
  }
  if (___arguments.scrollTop > 250 && ___arguments.scrollTop < 300) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip263.png)';
  }
  if (___arguments.scrollBottom > 0 && ___arguments.scrollBottom < 50) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9fa1b9acfefce364d9f37ef866b9cbde'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip062.png)';
  }
  if (___arguments.scrollBottom > 50 && ___arguments.scrollBottom < 100) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9fa1b9acfefce364d9f37ef866b9cbde'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip061.png)';
  }
  if (___arguments.scrollBottom > 100 && ___arguments.scrollBottom < 150) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9fa1b9acfefce364d9f37ef866b9cbde'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip060.png)';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/legal/components/82e1ad551244560549aead69dfc4786c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://apps.apple.com/tt/app/streettt/id1630838316', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/legal/components/d4c82846aa9a138737c66a06374678d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://play.google.com/store/apps/details?id=com.streettt.admin.signin2', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/cb5d4e74ae8a79457ee4d6af2eb3b9df/bundle.js', [], () => ({
  /* content */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop > 100 && ___arguments.scrollTop < 150) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip260.png)';
  }
  if (___arguments.scrollTop > 150 && ___arguments.scrollTop < 200) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip261.png)';
  }
  if (___arguments.scrollTop > 200 && ___arguments.scrollTop < 250) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip262.png)';
  }
  if (___arguments.scrollTop > 250 && ___arguments.scrollTop < 300) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6ff98fbf1abbbf792c6f0709aa4f208c'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip263.png)';
  }
  if (___arguments.scrollBottom > 0 && ___arguments.scrollBottom < 50) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9fa1b9acfefce364d9f37ef866b9cbde'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip062.png)';
  }
  if (___arguments.scrollBottom > 50 && ___arguments.scrollBottom < 100) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9fa1b9acfefce364d9f37ef866b9cbde'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip061.png)';
  }
  if (___arguments.scrollBottom > 100 && ___arguments.scrollBottom < 150) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('9fa1b9acfefce364d9f37ef866b9cbde'))['backgroundImage'] = 'url(https://backendlessappcontent.com/D3828E7D-B42E-B69F-FFFC-EFE55E612400/01DF8195-BDBA-4FA3-ABF0-170EA99D90DE/files/web+app/Clip060.png)';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/modguide/components/82e1ad551244560549aead69dfc4786c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://apps.apple.com/tt/app/streettt/id1630838316', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/d4c82846aa9a138737c66a06374678d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://play.google.com/store/apps/details?id=com.streettt.admin.signin2', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/7529b502b2be9bbfafdc6b9e317597e6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7529b502b2be9bbfafdc6b9e317597e6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2bb1a17dac0f33ff7d577785fcf6e461', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d0ea0eecbe968a2c5a6467c3d847649', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('caf52d58b74f71ad4c0e5acf40c73ce5')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/2bb1a17dac0f33ff7d577785fcf6e461/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7529b502b2be9bbfafdc6b9e317597e6', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2bb1a17dac0f33ff7d577785fcf6e461', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d0ea0eecbe968a2c5a6467c3d847649', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('caf52d58b74f71ad4c0e5acf40c73ce5')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/37777ba97b17b5a39496cedda435720b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('37777ba97b17b5a39496cedda435720b', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('09996c0ce033e87fe5dbf82524323f1e', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a79487361b28095c2bef79fd57120aa', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f8c2c6e65afc4bfc8b2aa2e73b578dad')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/09996c0ce033e87fe5dbf82524323f1e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('09996c0ce033e87fe5dbf82524323f1e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('37777ba97b17b5a39496cedda435720b', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1a79487361b28095c2bef79fd57120aa', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f8c2c6e65afc4bfc8b2aa2e73b578dad')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/9bbcede684a836744ab142e9c07566e3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9bbcede684a836744ab142e9c07566e3', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4bcf4f86bb67009f3fe129c6ed33794a', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('265e74a4e911f602d78b062121af20e6', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f6c85aa11332435b46d850776b37b2f6')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/4bcf4f86bb67009f3fe129c6ed33794a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4bcf4f86bb67009f3fe129c6ed33794a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9bbcede684a836744ab142e9c07566e3', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('265e74a4e911f602d78b062121af20e6', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f6c85aa11332435b46d850776b37b2f6')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/97c9d58c7c6a83eebb0dddff01db3ad6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('97c9d58c7c6a83eebb0dddff01db3ad6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1aa58b24b2508344f551b3e40518a64', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('999b9f83259bbf695f75d29522da2a16', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7b588bc1c87a9e14510f84dc60152524')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/d1aa58b24b2508344f551b3e40518a64/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1aa58b24b2508344f551b3e40518a64', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('97c9d58c7c6a83eebb0dddff01db3ad6', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('999b9f83259bbf695f75d29522da2a16', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7b588bc1c87a9e14510f84dc60152524')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/f12d620954ce4f86226358a6c9430161/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f12d620954ce4f86226358a6c9430161', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc9b5fad048a8074f182231b1f188ff3', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d5a572b95319fa6df79b75e9cf9d2759', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('072fa3c968d282f91dc9d31a4dec0736')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/dc9b5fad048a8074f182231b1f188ff3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc9b5fad048a8074f182231b1f188ff3', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f12d620954ce4f86226358a6c9430161', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d5a572b95319fa6df79b75e9cf9d2759', false);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('072fa3c968d282f91dc9d31a4dec0736')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/b4f7facf58067fec87be368dec34f5c9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4f7facf58067fec87be368dec34f5c9', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6517393922eedeed889e4d9514ec3a3c', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95ffbfd89eae1271799a39992a1f5083', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f71f7452e589a5352235aa4a8b1e1b83')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/6517393922eedeed889e4d9514ec3a3c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6517393922eedeed889e4d9514ec3a3c', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('95ffbfd89eae1271799a39992a1f5083', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4f7facf58067fec87be368dec34f5c9', true);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f71f7452e589a5352235aa4a8b1e1b83')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/c453db66198d3da880c18f4dd53dfdd9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick *//* handler:onClick */
  /* content */
}))

define('./pages/modguide/components/852f150d4797181e524d6fe6cb494054/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')), 'show');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_6afcab82fb44fab7e676ce9af4128d47/logic/1ad88e1983366f67d994e616a1821ec8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('425885819713548457e4e96278eeb441', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a3fe5efb832767e91d192453df629fad', false);
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('5f84e7c2c817fe929daa3b0a0e33b1eb'))['font-weight'] = 'Bold';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c25a64bec7fdf418c718c5e64b9b58e2'))['font-weight'] = 'Normal';
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('9835f1f37f1e6aebf4973cedd7592018')));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_6afcab82fb44fab7e676ce9af4128d47/logic/c25a64bec7fdf418c718c5e64b9b58e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a3fe5efb832767e91d192453df629fad', true);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('425885819713548457e4e96278eeb441', false);
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('5f84e7c2c817fe929daa3b0a0e33b1eb'))['font-weight'] = 'Normal';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c25a64bec7fdf418c718c5e64b9b58e2'))['font-weight'] = 'Bold';
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('9835f1f37f1e6aebf4973cedd7592018')));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3e0f4c330fbb940943c771cd1c0cb50b/logic/852f150d4797181e524d6fe6cb494054/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')), 'show');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_09ccf54a78249815939d136e0d2dbae7/logic/852f150d4797181e524d6fe6cb494054/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).slice(-1)[0] == 'active') {
    return (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')).pop() && ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')).pop());
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('852f150d4797181e524d6fe6cb494054')), 'active');
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('10ab0392edd20c5a350e7e9b04da5cae')), 'show');
  }

  },
  /* handler:onClick */
  /* content */
}))

